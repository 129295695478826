.boxbox {
  display: block;
  -webkit-transform: translate(0%, 0%)!important;
  transform: translate(0%, 0%)!important;
  width: 100%;
}
.tableHover {
  border-collapse: collapse!important;
  overflow: hidden!important;
  border:solid 4px white
}

.tableHover tbody {
  z-index: -10px;

}

.tableHover th {
  border-bottom: 1px solid lightgray !important;
  padding: 10px !important;
  margin: 0;
}

.tableHover th,
.tableHover td {
  background-color: rgba(255, 255, 255, 0.2)!important;
}
.tableHover th {
  text-align: left!important;
}
.tableHover thead th {
}
.tableHover tbody tr:hover {
  background-color: rgba(206, 206, 206, 0.6)!important;
}
.tableHover tbody td {
  position: relative!important;
  width: auto !important;
}

.tableHover td:hover{
  background-color: black !important;
  color:white;
}

.tableHover tbody td:hover:before {
  content: ""!important;
  position: absolute!important;
  left: 0!important;
  right: 0!important;
  top: -9999px!important;
  bottom: -9999px!important;
  background-color: rgba(206, 206, 206, 0.6)!important;
  z-index: -1!important;
}

.box{
  display: block !important;
  height: auto;
  position: relative;
  width: 100%; /* Adjust as needed */
  box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.464)!important;
  margin-bottom: 15px;
}

.sort{
  transition: 0.3s ease-in-out !important;
  height:0.9em !important;
  line-height: 0px !important;
  padding:0px !important
}

.sortFlip{
  transform: scale(-1) !important;
}

.sortHover{
  opacity: 0 !important;
}

.sortLabel:hover .sortHover{
  opacity: 0.5 !important;
}

.page-size{
  right:0px !important;
  transform: translateY(-50%);
  top:50%;
  margin-bottom: 100px;
}

.fc-scrollgrid {
  min-width: 700px !important;
}
.fc-view{
  overflow-x: scroll !important;
}
.weekNumber{
  background-color: red; 
  color:red;
}

/*FullCalendar Events*/



.fc-h-event { /* allowed to be top-level */
  display: block;
  margin:4px;
  padding:2px;
  border: 2px solid black !important;
  color:black;
  background-repeat: no-repeat;
  background-color: blanchedalmond;
  white-space: normal;
}

.fc-h-event *{ /* allowed to be top-level */
  text-align: center;
  color:black;
  font-weight: 600;
}

.fc-h-event:hover{ /* allowed to be top-level */
  transform: scale(1.1);
  transition:cubic-bezier(0.175, 0.885, 0.32, 1.275) all 0.3s;
  cursor: pointer;
  user-select: none;
}

:root {
  --fc-page-bg-color: "#000000" !important;
}

.fc-col-header th{
  background-color: black;
}

.eventModal{
 transform: translateX(-50%) !important;
}