#my-div {
  transform-style: preserve-3d;
  transform: perspective(1000px);
}

.card {
  border: solid black 8px;
  border-radius: 20px;
  background-repeat: no-repeat;background-image: radial-gradient(circle, rgba(212, 0, 0, 1), rgba(133, 0, 0, 1));
  animation: card-glow 5s infinite;
  animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

@keyframes card-glow {
  0% {
    box-shadow: 0px 0px 0px #ff0000;
  }
  50% {
    box-shadow: 0px 0px 100px #ff0000;
  }
  100% {
    box-shadow: 0px 0px 0px #ff0000;
  }
}

.card * {
  animation: fadein 1s ease-in;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
}

.card-container {
  animation: drop 2s ease-in;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.card-container * {
  text-align: center;
}

.card img {
  transform: translateZ(40px);
}

.card .name {
  transform: translateZ(60px);
}

.logoTilt {
  transform: translateZ(60px);
}
.awards-lineheight {
  line-height: 45px;
}

@screen maxlg {
  .lineheight {
    line-height: 1rem !important;
  }

  .awards-lineheight {
    line-height: 28px;
  }
}

.tilt {
  transform: translateZ(60px);
}

.fullName {
  font-variant: small-caps;
  transform: translateZ(30px);
}

.page {
  display: flex !important;
  justify-content: space-evenly !important;
}

.gold {
  font-variant: small-caps;
  background-image: linear-gradient(
    to right,
    #cb9b51 0%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 100%
  );
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gold-animate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0), #fff, rgba(0, 0, 0, 0));
  background-repeat: no-repeat;
  animation: glow 3s linear infinite;
  font-variant: small-caps;
  background-size: 80%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bestD {
  font-variant: small-caps;
  background-image: linear-gradient(
    to right,
    #0055ff 0,
    #00ccfa 50%,
    #0055ff 100%
  );
  color: transparent;
  -webkit-background-clip: text;
  transform: translateZ(30px);
}
.bestD-animate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0),
    #00ffb7,
    rgba(0, 0, 0, 0)
  );
  background-repeat: no-repeat;
  animation: glow 2s linear infinite;
  font-variant: small-caps;
  background-size: 80%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cship-animate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0),
    #ffffff,
    rgba(0, 0, 0, 0)
  );
  background-repeat: no-repeat;
  animation: glow 2s linear infinite;
  font-variant: small-caps;
  background-size: 80%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-family: "Anton", sans-serif;
  font-weight: 400;
}
.wrapper h1 {
  -webkit-transform: skew(-10deg, 0);
  transform: skew(-10deg, 0);
  white-space: nowrap;
  letter-spacing: 4px;
  z-index: 1;
  color: whitesmoke;
  animation: beat 2s ease infinite;
}

@keyframes beat {
  0% {
    transform: skew(-10deg, 0) scale(1);
  }
  5% {
    transform: skew(-10deg, 0) scale(1.15);
  }
  15% {
    transform: skew(-10deg, 0) scale(1);
  }
  45% {
    transform: skew(-10deg, 0) scale(1);
  }
  50% {
    transform: skew(-10deg, 0) scale(1.1);
  }
  55% {
    transform: skew(-10deg, 0) scale(1);
  }
  60% {
    transform: skew(-10deg, 0) scale(1.3);
  }
}

@keyframes glow {
  0% {
    background-position: -2000%;
  }
  100% {
    background-position: 2000%;
  }
}

.grow {
  animation: grow 1s ease-in;
}

@keyframes grow {
  0% {
    transform: scale(0.1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes drop {
  0% {
    opacity: 0;
    transform: translate(-50%, -65%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@screen lg {
  .font-shadow {
    /* 1 pixel black shadow to left, top, right and bottom */
    text-shadow: -3px 3px black, 0 3px black;
  }

  .font-shadow-small {
    /* 1 pixel black shadow to left, top, right and bottom */
    text-shadow: -3px 10px black, 0 3px black;
  }

  .font-shadow2 {
    /* 1 pixel black shadow to left, top, right and bottom */
    text-shadow: -1px 5px black, 0 1px black;
  }

  .font-shadow3 {
    /* 1 pixel black shadow to left, top, right and bottom */
    text-shadow: -2px 7px black, 0 2px black;
  }
}

@screen sm {
  .font-shadow {
    /* 1 pixel black shadow to left, top, right and bottom */
    text-shadow: -3px 7px black, 0 3px black;
  }

  .font-shadow-small {
    /* 1 pixel black shadow to left, top, right and bottom */
    text-shadow: -3px 10px black, 0 3px black;
  }

  .font-shadow2 {
    /* 1 pixel black shadow to left, top, right and bottom */
    text-shadow: -1px 5px black, 0 1px black;
  }

  .font-shadow3 {
    /* 1 pixel black shadow to left, top, right and bottom */
    text-shadow: -2px 5px black, 0 2px black;
  }
}

@keyframes animate-shadow {
  0% {
    text-shadow: -2px 7px black, 0 2px black;
  }
  100% {
    text-shadow: 0px 7px black, 0 2px black;
  }
  100% {
    text-shadow: -2px 7px black, 0 2px black;
  }
}

.card {
  width: 100%;
  height: 100%;
}

.close-icon {
  position: relative;
  display: flex;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #000;
  transition: transform 0.3s ease;
  overflow: hidden;
  border: solid white 4px;
}

.close-icon span {
  display: block;
  position: absolute;
  border-radius: 20px;
  width: 80%;
  height: 10px;
  background-color: #fff;
  transition: transform 0.3s ease;
  transform-origin: center;
}

.close-icon:hover span:nth-child(1) {
  transform: rotate(45deg);
}

.close-icon:hover span:nth-child(2) {
  transform: rotate(-45deg);
}

.close-icon span {
  top: calc(50% - 4px);
  transform: scale(1.2);
}
