
.player-profile img{
    transition: 0.5s;
}

.player-profile:hover img:first-child{ --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);}

.player-profile:hover img:last-child{
    scale: 1.1;
}