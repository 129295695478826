@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Anton&family=Lilita+One&family=Monoton&family=Poppins:wght@200;300;400;500;600;700;800&family=Press+Start+2P&family=Saira+Stencil+One&display=swap");

@font-face {
  font-family: Fugaz One;
  src: url(../public/assets/font/FugazOne-Regular.ttf);
}

@font-face {
  font-family: Bungee;
  src: url(../public/assets/font/Bungee-Regular.ttf);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.stats-font {
  font-family: Bungee;
}

table * {
  font-family: "Poppins";
}

body {
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background-animate {
  animation: background-animation 58s ease;
  transition-timing-function: cubic-bezier(0, 1.01, 0.99, 0.04);
  transform: translate(-50%, -50%); transition: all;
}

.background-animate2 {
  animation: background-animation2 58s ease;
  transition-timing-function: cubic-bezier(0, 1.01, 0.99, 0.04);
  
  transform: translate(-45%, -40%);transition: all;
}

.background-animate3 {
  animation: background-animation3 58s ease;
  transition-timing-function: cubic-bezier(0, 1.01, 0.99, 0.04);
  
  transform: translate(-45%, -37%);;transition: all;
}

@keyframes background-animation {
  0% {
    transform: translate(-35%, -60%);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }
  100% {
    transform: translate(-40%, -50%);
    opacity: 0;
  }
}
@keyframes background-animation2 {
  0% {
    transform: translate(-48%, -45%);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }
  100% {
    transform: translate(-45%, -40%);
    opacity:0;
  }
}

@keyframes background-animation3 {
  0% {
    transform: translate(-45%, -50%);
    opacity:0;
  }
  5% {
    opacity:1;
  }

  90% {
    opacity:1;
  }
  100% {
    transform: translate(-45%, -37%);
    opacity:0;
  }
}

.MuiPaginationItem-icon > svg {
  border: 2px solid blue; /* Change the border color as needed */
}

.black-bg {
  background-attachment: fixed;
  background-image: radial-gradient(rgba(0, 0, 0, 0.728), black);
  opacity: 0.99;
  backdrop-filter: blur(5px);
}

.body-bg {
  background-attachment: fixed;
  background-image: radial-gradient(
    rgba(200, 200, 200, 0.284),
    rgba(34, 34, 34, 0.918)
  );
  opacity: 0.9;
}

.fade-in {
  animation: fade-in 0.5s ease-in;
  opacity: 0;
  will-change: opacity;
  animation-fill-mode: forwards ;
}

.fade-down {
  opacity: 0;
  animation: fade-down 700ms ;
  animation-fill-mode: forwards ;
  transition-timing-function:  cubic-bezier(0,0,0,1);
 
}

@keyframes fade-down {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-away {
  opacity: 0;
  animation: fade-away 2s ease-in;
}

@keyframes fade-away {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.bgBlackRed {
  background-repeat: no-repeat;
  background-image: radial-gradient(rgb(255, 2, 2), rgb(75, 0, 0));
}
.bgBlack {
  background-repeat: no-repeat;
  background-image: radial-gradient(#2f2f2f, black);
}

.bgRed {
  background-repeat: no-repeat;
  background-image: radial-gradient(rgb(255, 0, 0), #ab0000);
}

.container {
  margin-top: 10vh;
  width: 100% !important;
  max-width: none;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: fit-content !important;
  align-items: center;
  animation: fade-in 0.5s ease-in;
}
.content {
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.lg-shadow {
  filter: drop-shadow(-10px 10px 15px rgba(0, 0, 0, 1));
  -webkit-filter: drop-shadow(-10px 10px 15px rgba(0, 0, 0, 1));
}

.md-shadow:hover {
  filter: drop-shadow(0px 0px 10px rgb(255, 255, 255));
  -webkit-filter: drop-shadow(0px 0px 10px rgb(255, 255, 255));
}

.sm-shadow {
  filter: drop-shadow(-4px 4px 5px rgba(0, 0, 0, 1));
  -webkit-filter: drop-shadow(-4px 4px 5px rgba(0, 0, 0, 1));
}

.sm-shadow-red {
  filter: drop-shadow(-0px 0px 8px rgba(255, 0, 0, 0.782));
  -webkit-filter: drop-shadow(-0px 0px 8px rgb(255, 0, 0, 0.782));
}

.xs-shadow {
  filter: drop-shadow(-2px 2px 2px rgba(0, 0, 0, 1));
  -webkit-filter: drop-shadow(-2px 2px 2px rgba(0, 0, 0, 1));
}

.xxs-shadow {
  filter: drop-shadow(-1px 1px 1px rgba(0, 0, 0, 1));
  -webkit-filter: drop-shadow(-1px 1px 1px rgba(0, 0, 0, 1));
}

.underLine-shadow {
  filter: drop-shadow(-4px 4px 5px rgba(0, 0, 0, 1));
  -webkit-filter: drop-shadow(-4px 4px 3px rgba(0, 0, 0, 1));
}

::-webkit-scrollbar {
  width: 0px;
}

/* Safari */
:-webkit-full-screen {
  background-color: transparent;
}

/* IE11 */
:-ms-fullscreen {
  background-color: transparent;
}

/* Standard syntax */
:fullscreen {
  background-color: transparent;
}

.blury {
  backdrop-filter: blur(10px);
}

.sliderFlex {
  display: flex !important;
}

nav * {
  font-family: Fugaz One;
}

.selector {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.smallCaps {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.smallCaps span:first-child {
  z-index: 1;
}

.smallCaps span:last-child {
  font-size: 70%;
}

.navbar {
  display: flex;
  background-repeat: no-repeat;
  background-image: radial-gradient(#ff0000, 75%, #ab0000);
  --tw-shadow: 0 15px 30px rgb(0 0 0 / 1);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.navbar-toggle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.toggle-icon {
  width: 100%;
  height: 4px;
  background-color: black;
  transition: transform 0.3s ease;
}

.navbar-toggle.open .toggle-icon:nth-child(1) {
  transform: rotate(45deg) translate(6px, 5px);
}

.navbar-toggle.open .toggle-icon:nth-child(2) {
  opacity: 0;
}

.navbar-toggle.open .toggle-icon:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -6px);
}

.navbar-menu {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  transform: translateY(-100%);
  display: none;
}

.navbar-menu.open {
  transform: translateY(0);
  opacity: 1;
  display: flex;
}

@media (min-width: 1024px) {
  .navbar {
    flex-direction: row;
    padding: 20px;
  }

  .navbar-brand {
    margin-right: 20px;
  }

  .navbar-toggle {
    display: none;
  }

  .navbar-menu {
    transform: translateY(0);
    opacity: 1;
    flex-direction: row;
    gap: 20px;
  }
}

.activeNav {
  width: 100%;
  top: 0px;
  transition: 0.3s linear;
  display: flex;
  justify-content: stretch;
  align-items: center;
  transition: 0.5s;
}

.active {
  color: white;
}

.active span:first-child::after {
  transform: translate(-0.1em, 0.1em); /* Adjust the shadow offset */
  filter: blur(2px);
}
.active span:last-child::after {
  transform: translate(-0.13em, 0.13em); /* Adjust the shadow offset */
  filter: blur(2px);
}

.NavLink span {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition: 200ms;
}

nav span {
  position: relative;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition: 200ms;
}

nav span::after {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition: 200ms;
  content: attr(data-content);
  position: absolute;
  top: 0px; /* Adjust the vertical position of the shadow */
  left: 0px; /* Adjust the horizontal position of the shadow */
  color: rgba(0, 0, 0, 1); /* Shadow color and opacity */
  z-index: -1; /* Place the shadow behind the text */
}

.customShadow {
  position: relative;
  -webkit-text-stroke: 6px black; /* width and color */
}
.customShadow::after {
  transform: translate(-0.1em, 0.1em); /* Adjust the shadow offset */
  content: attr(data-content);
  filter: blur(3px);
  position: absolute;
  bottom:0;
  left: 0px; /* Adjust the horizontal position of the shadow */
  color: rgb(0, 0, 0); /* Shadow color and opacity */
  z-index: -1; /* Place the shadow behind the text */
  -webkit-text-stroke: 8px black; /* width and color */
}

.miniTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.miniTitle .customShadow {
  -webkit-text-stroke: 6px black; /* width and color */
}

.miniTitle .customShadow::after {
  transform: translate(-0.1em, 0.1em); /* Adjust the shadow offset */
  -webkit-text-stroke: 6px black; /* width and color */
}

nav li:hover,
.NavLink:hover {
  color: white;
}

.ddlActive {
  color: white;
}

.nav-full-screen {
  -webkit-animation: resize 0.5s ease-in;
  -moz-animation: resize 0.5s ease-in;
  -ms-animation: resize 0.5s ease-in;
  -o-animation: resize 0.5s ease-in;
  animation: resize 0.5s ease-in;
}

@keyframes resize {
  100% {
    height: 100vh;
  }
}

.hiddenNav {
  width: 100%;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 1);
  top: -80px;
  transition: 0.5s ease;
}

.ddl {
  transform: translate(-50%, -10px);
  transition: 0.3s;
  visibility: hidden;
  user-select: none;
  opacity: 0;
}

.ddlOpen {
  transform: translate(-50%, 0);
  transition: 0.3s;
  opacity: 1;
}

.button {
  --b: 3px; /* border thickness */
  --s: 0.15em; /* size of the corner */
  --c: white;

  padding: calc(0.05em + var(--s)) calc(0.3em + var(--s));
  color: var(--c);
  --_p: var(--s);
  background: conic-gradient(
      from 90deg at var(--b) var(--b),
      #0000 90deg,
      var(--c) 0
    )
    var(--_p) var(--_p) / calc(100% - var(--b) - 2 * var(--_p))
    calc(100% - var(--b) - 2 * var(--_p));
  transition: 0.3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: 0.2em;
}
.button:hover,
.button:focus-visible {
  --_p: 0px;
  outline-color: var(--c);
  outline-offset: 0.05em;
}
.button:active {
  color: #fff;
}

.button {
  font-family: system-ui, sans-serif;
  font-size: 4rem;
  cursor: pointer;
  border: none;
  margin: 0.1em;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  margin-bottom: 30px;
}

.photo:hover::before {
  opacity: 0;
  left: 50%;
  transform: translateX(-100%);
  content: attr(data-content);
  position: absolute;
  bottom: 5%;
  background-color: rgb(0 0 0 / 0.7);
  color: white;
  padding: 8px 12px;
  min-width: 200px;
  height: fit-content;
  text-align: center;
  z-index: 10;
  font-family: "Bungee";
  animation: photoDesc 6s cubic-bezier(0, 1, 1, 0);
}

@keyframes photoDesc {
  0% {
    transform: translateX(-100%);
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateX(0%);
    opacity: 0.8;
  }
}
.grid-item {
  transition: transform 0.3s ease-in-out; /* Adjust the duration and easing as needed */
}

.photo-tilt {
  transform-style: preserve-3d;
  transform: perspective(1000px);
}

.tilt-item {
  transform: translateZ(100px) translateX(-50%);
}

@keyframes zoom {
  0% {
    transform: scale(0.1);
    opacity: 0.5;
  }
}

.zoom {
  animation: zoom 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.dot-bouncing {
  position: relative;
  height: 10px;
}
.dot-bouncing span:nth-child(1)::before {
  content: "🏀";
  display: inline-block;
  position: relative;
  animation: dot-bouncing 1.2s infinite;
  animation-delay: 0s;opacity: 0;
}
.dot-bouncing span:nth-child(2)::before {
  content: "🏀";
  display: inline-block;
  position: relative;
  animation: dot-bouncing 1.2s infinite;
  animation-delay: 0.2s;opacity: 0;
}
.dot-bouncing span:nth-child(3)::before {
  content: "🏀";
  display: inline-block;
  position: relative;
  animation: dot-bouncing 1.2s infinite;
  animation-delay: 0.4s;opacity: 0;
}


@keyframes dot-bouncing {
  0% {
    top: -20px;
    animation-timing-function: ease-in;opacity: 1;
  }
  34% {
    transform: scale(1, 1);
  }
  35% {
    top: 20px;
    animation-timing-function: ease-out;
    transform: scale(1.5, 0.5);
  }
  45% {
    transform: scale(1, 1);
  }
  90% {
    top: -20px;
  }
  100% {
    top: -20px;
    animation-timing-function: ease-in;opacity: 1;
  }
}

.fiche-animation:nth-child(3) { animation-delay: 0.5s; }
.fiche-animation:nth-child(2) { animation-delay: 0.25s; }
.fiche-animation:nth-child(1) { animation-delay: 0.1s; }

.fiche-animation { 
  will-change: transform;
  animation : fiche-animation 3s infinite;
  border-radius: 20px;
  will-change: transform, filter;
}

.fiche-scale-animation {
  animation : scale-animation 3s infinite;
}

@keyframes fiche-animation {
  0% {
  }50% {
    filter: drop-shadow(0px 0px 10px black);
  -webkit-filter: drop-shadow(0px 0px 10px black);
  transform: scale(1.005);
  }
}

@keyframes scale-animation {
  0% {
  }50% {
  transform: scale(1.005);
  }
}


.flip-card {
  background-color: transparent;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  border-radius: 10px;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  border-radius: 10px;
}



/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}


/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}

.gradient-border {
  border-image: linear-gradient(to top , #ff0000, #cf0000); /* Adjust the gradient colors */ border-image-slice: 1;
}

.gradient-border2 {
  border-image: linear-gradient(to top , #ff0000, #2c0000); /* Adjust the gradient colors */ border-image-slice: 1;
}

.gradient-border::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  border: 20px solid transparent; /* Set the color for the top border */
  border-top: 20px solid black; /* Set the color for the top border */
 
}

.location iframe{
  width: inherit;
  height: inherit;
}