
.bgBestD{
    background-image: linear-gradient(
        to right,
        #0055ff 0,
        #00ccfa 50%,
        #0055ff 100%
        );

}

.bgBestD-animate{
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0), #00ffb7, rgba(0,0,0,0));
    background-repeat: no-repeat;
    animation: glow 2s linear infinite;
    background-size: 80%;

}

.bestDProfile, .bestDProfile img{
    aspect-ratio: 1/1;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition: 350ms ease;
}


.bestDProfile:hover{
    transform: scale(1.1);
}

.bestDText {
    font-family: 'Righteous' !important
}
@screen lg{

   
    .font-shadow {
        /* 1 pixel black shadow to left, top, right and bottom */
        text-shadow: -3px 10px 5px black !important;
    }
}

@screen sm{
    .font-shadow {
        /* 1 pixel black shadow to left, top, right and bottom */
        text-shadow: -3px 7px 5px black !important}
}